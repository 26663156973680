@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    * {
        font-family: 'Poppins', sans-serif;
    }
    .center {
        @apply flex justify-center items-center
    }
    .btn-black {
        @apply bg-black p-2 text-lg md:text-xl text-white center rounded-[5px] gap-3 md:px-7  md:py-4;
    }
}

::-webkit-scrollbar {
    display: none;
}